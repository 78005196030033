.reservation-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 0;
}

.reservation-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.top {
    align-self: flex-start;
}

.bottom {
    align-self: flex-end;
}

.reservation-step {
    max-width: 35rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5rem 0;
}

.reservation-step>img {
    width: 10rem;
    height: auto;
    margin-right: 1.5rem;
}

.reservation-step>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.reservation-step p, .reservation-step h2 {
    width: 100%;
    margin: 0;
}

.reservation-step h2 {
    font-size: 2rem;
}

@media (max-width: 992px) {
    .reservation-section {
        padding: 0;
    }

    .row {
        flex-direction: column;
        justify-content: center;
    }

    .reservation-step {
        flex-direction: column;
        margin: 5rem 0 0 0;
    }

    .reservation-step h2, .reservation-step p {
        text-align: center;
    }

    .reservation-container {
        padding-bottom: 5rem;
    }
}