.order-section {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-section>div {
    width: 50%;
    height: 30rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-section h1 {
    font-size: 3rem;
    width: 60%;
    text-align: left;
}

.order-section>div:first-child {
    background-color: var(--lighter-orange);
}

.order-section>div:last-child {
    background-color: var(--light-orange);
}

.order-section>img {
    width: 20rem;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.order-steps {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 5rem;
}

.order-steps h1 {
    margin: 4rem 0 0 0;
}

.order-steps-inner-container {
    width: 70%;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 15px 15px;
}

.order-steps-inner-container>div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.order-step {
    width: 25rem;
    height: 35rem;
    margin: 5rem 2rem;
    position: relative;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

.order-step img {
    width: auto;
    height: 35rem;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
}

.order-step h3 {
    width: 75%;
    margin: 1rem auto;
    text-align: center;
    color: #fff;
    font-weight: 400;
}

@media (max-width: 992px) {
    .order-section {
        width: 100%;
        flex-direction: column;
    }

    .order-section>img {
        width: 19rem;
    }

    .order-section>div {
        width: 100%;
        height: 31rem;
    }

    .order-section h1 {
        width: 90%;
        font-size: 2.5rem;
        text-align: center;
    }

    .order-section>div:first-child {
        align-items: flex-start;
    }

    .order-section>div:last-child {
        align-items: flex-end;
    }

    .order-steps-inner-container {
        width: 100%;
    }

    .order-steps-inner-container>div {
        flex-direction: column;
    }
}