.layout {
    width: 100%;
    height: 100%;
    margin: 6.5rem 0 0 0;
}

.main {
    width: 100%;
    height: 100%;
    min-height: 90.5vh;
    background-color: #f5f5f5;
    position: relative;
}