/* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 2.6rem;
    height: 2rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #fff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    top: 22px !important;
    right: 22px !important;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #000;
    height: 25px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 40vw !important;
    top: 0 !important;
    right: 0 !important;
    background-color: #f5f5f5;
    box-shadow: 0px 3px 6px #00000029;
}

.bm-menu-wrap::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

/* General sidebar styles */
.bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden !important;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #000;
}

/* Wrapper for item list */
.bm-item-list {
    color: #000;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: var(--dark);
    text-decoration: none;
    margin: 2rem 0;
    font-size: 2rem;
}

/* Styling of overlay */
.bm-overlay {
    background: #000 !important;
}


@media (max-width: 576px) {
    .bm-menu-wrap {
        width: 70vw !important;
    }

    .bm-item {
        margin: 2rem 0;
    }
}