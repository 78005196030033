.contacts-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.contacts-section>div {
    width: 80rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem 0;
}

.contacts-section h1 {
    font-weight: 500;
    margin-top: 0;
    text-align: center;
}

.contacts-details {
    background-color: var(--orange);
}

.contacts-details h1, .contacts-details p, .contacts-details h3 {
    width: 100%;
    color: #fff;
}

.contacts-details>div {
    max-width: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contacts-details>div, .contacts-form {
    max-width: 50rem;
    margin: 0 5%;
}

.contacts-details>div>div>p {
    font-size: 2rem;
    text-align: center;
}

.contacts-item {
    width: 100%;
    border: 1px #fff solid;
    border-radius: 15px;
    margin: 2rem 0;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contacts-item h3, .contacts-item p {
    text-align: center;
    font-weight: 400;
}

.contacts-form {
    max-width: 50rem;
}

.contacts-form>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contacts-form button {
    width: 100% !important;
    margin-top: 2rem;
}

.contacts-form input, .contacts-form textarea {
    margin: 1rem 0;
}

@media (max-width: 576px) {
    .contacts-details>div, .contacts-form {
        margin: 0 10%;
    }
}

@media (max-width: 992px) {
    .contacts-section {
        flex-direction: column;
    }

    .contacts-section>div {
        width: 100%;
    }
}