.about-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20rem 0 3rem 0;
}

.about-text-container {
    width: 80rem;
}

.about-text-container h1, .about-text-container p {
    text-align: center;
}

.about-text-container h1 {
    font-size: 3.4rem;
    font-weight: 400;
    position: relative
}

.about-text-container h1::before {
    width: 25%;
    content: '';
    display: block;
    position: absolute;
    bottom: -7%;
    left: 50%;
    transform: translate(-50%);
    border-top: 3px solid var(--orange);
}

.about-text-container p {
    font-size: 2.5rem;
}

.dotted-grid {
    width: 80rem;
    height: 50rem;
    position: relative;
    margin: 5% 0;
    background: radial-gradient(rgba(0, 0, 0, 0.1) 3px, transparent 4px) 0 0 / 25px 25px;
}

.dotted-grid>div:nth-child(1) {
    position: absolute;
    top: -5%;
    left: -5%;
}

.dotted-grid>div:nth-child(2) {
    position: absolute;
    top: -5%;
    right: -5%;
}

.dotted-grid>div:nth-child(3) {
    position: absolute;
    bottom: 20%;
    left: -5%;
}

.dotted-grid>div:nth-child(4) {
    position: absolute;
    bottom: 20%;
    right: -5%;
}

.about-item {
    max-width: 37rem;
    height: 15rem;
    background-color: #fff;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px #00000029;
}

.stroke {
    height: 80%;
    width: 2px;
    background-color: var(--gray);
    margin: 0 7%;
}

.about-item img {
    width: 25%;
    height: auto;
}

.about-item h3 {
    font-weight: 400;
}

@media (max-width: 576px) {
    .business-header {
        bottom: -20%
    }

    .business-header h1 {
        font-size: 2.5rem;
    }

    .about-section {
        padding: 20rem 0 3rem 0;

    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .about-section {
        padding: 8rem 0 3rem 0;
    }
}

@media (max-width: 992px) {
    .about-item {
        max-width: 45rem;
    }

    .about-item img {
        width: 30%;
    }

    .about-text-container {
        width: 90%;
    }

    .about-item {
        position: static !important;
        margin: 2% 5%;
    }

    .dotted-grid {
        width: 100%;
        height: 100rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }
}