html {
  /* 10 px base */
  font-size: 62.5%;
  scroll-behavior: smooth;
  cursor: default;
  --darkGrey: #363636;
  --grey: #4e4e4e;
  --lighter-orange: #FFC081;
  --light-orange: #EE8E6C;
  --orange: #ff8559;
  --gray: #707070;
  --red: #ff3030;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}