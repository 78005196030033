.container {
    width: 100%;
    height: 300px;
    position: absolute;
    bottom: 0;
    background-color: transparent;
}

.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}