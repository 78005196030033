.search-section {
    height: calc(100vh - 6rem);
    max-height: 800px;
    min-height: 550px;
    position: relative;
    display: flex;
    overflow: hidden;
}

.search-text-container {
    width: 70%;
    margin-right: 10rem;
}

.search-text-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    position: relative;
    z-index: 999;
}

.search-text-section>h1 {
    width: 90%;
    font-size: 4rem;
    font-weight: 900;
    margin: 0;
}

.search-text-section>p {
    width: 90%;
    font-size: 2.8rem;
    font-weight: 500;
}

.food-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.food-img {
    width: auto;
    height: 39rem;
    margin-top: 4rem;
}

.search-form {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (min-width: 993px) {
    .search-text-container>div {
        height: 39rem;
        margin-bottom: 10rem
    }

    .search-image-text-container {
        width: 100%;
        margin-top: 5rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -75%);
        z-index: 999;
    }

    .food-img-container {
        width: 30%;
    }

    .search-form {
        width: 30%;
        position: absolute;
        bottom: 4%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


@media (max-width: 375px) {
    .search-text-section>h1 {
        font-size: 2.5rem !important;
    }

    .search-text-section>p {
        font-size: 1.8rem !important;
    }
}

@media (max-width: 992px) {
    .search-image-text-container {
        width: 100%;
        height: 100%;
    }

    .search-text-section>h1 {
        font-size: 3rem;
    }

    .search-text-section>p {
        font-size: 2rem;
    }

    .search-section {
        max-height: 680px;
        min-height: 635px;

    }

    .search-text-container {
        width: 100%;
        height: auto;
        margin: 0;
        flex-direction: column;
        position: absolute;
        top: 3%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search-text-section {
        margin-top: 2%;
        justify-content: flex-start;
    }

    .search-text-section>h1 {
        width: 100%;
        font-weight: 700;
    }

    .search-text-section>p {
        width: 100%;
        font-weight: normal;
    }

    .food-img-container {
        position: absolute;
        bottom: 9%;
        left: 50%;
        transform: translate(-50%);
        z-index: 999;
    }

    .food-img {
        width: auto;
        height: 30vh;
        max-height: 32rem;
        min-height: 25rem;
        margin-left: 4rem;
    }

    .search-form, .search-text-section {
        width: 90%;
        max-width: 60rem;
    }

    .search-form {
        position: relative;
        bottom: 45%;
        z-index: 999;
    }
}