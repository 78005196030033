.business-header {
    width: auto;
    height: 50rem;
    background-image: url("/public/business_header.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.business-header>div {
    width: 65rem;
    height: auto;
    position: absolute;
    bottom: -30%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.business-header img {
    width: 100%;
    height: 100%;
}

.business-header h1 {
    width: 100%;
    text-align: center;
    color: #fff;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
}

.business-header p {
    width: 100%;
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
}

@media (max-width: 576px) {
    .business-header>div {
        width: 32rem;
    }

    .business-header p {
        bottom: 2%;
    }

    .business-header h1 {
        font-size: 2.5rem;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .business-header>div {
        bottom: -9%;
        width: 55rem;
    }
}

@media (max-width: 768px) {
    .business-header h1 {
        font-size: 2.5rem;
    }
}