header {
  width: 100%;
  height: 6.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--orange);
  position: fixed;
  top: 0;
  z-index: 99999;
  box-shadow: 0 3px 6px #00000029;
}

.nav-bar {
  width: 92%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo img {
  width: 165px;
}

.nav-menu {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.nav-menu>a {
  color: #fff;
  font-size: 1.1rem;
  text-decoration: none;
  border-bottom: solid 1px transparent;
}

.nav-menu>a:hover {
  border-bottom: #fff solid 1px;
  color: #fff;
}

.nav-link {
  color: #fff;
  text-decoration: none;
}

.nav {
  min-width: 27rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.active {
  padding-bottom: 5px;
  border-bottom: 1.7px solid #fff;
}